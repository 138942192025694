<template>
  <div class="container">
    <ManagerForm
        first-name-imm=""
        last-name-imm=""
        phone-imm=""
        email-imm=""
        login-imm=""
        password-imm=""
        :is-show-info-imm="false"
        :is-edit="false"
        :is-login-used="isLoginUsed"
        @clearValidate="isLoginUsed = false"
        @create="createManager"/>
  </div>
</template>

<script>
import ManagerForm from "@/components/Manager/ManagerForm";
export default {
  name: "ManagersAdd",
  components: {ManagerForm},
  data(){
    return {
      isLoginUsed : false
    }
  },
  methods:{
    async createManager(data){
      this.isLoginUsed = false
      const res = await this.$axios.post("manager",{
        login: data.login,
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone
      });


      if (res.status == 400){
        this.isLoginUsed = true
      } else {
        if (res.data.id){
          await this.$router.push({name: 'ManagersEdit',params: {id : res.data.id}})
        }
      }



    }
  }
}
</script>

<style scoped lang="scss">
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  width: 100%;
}
</style>